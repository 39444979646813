import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Location } from '@angular/common';

import { NavbarComponent } from './layout/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { Subscription } from 'rxjs';
import { PlatformService } from './shared/services/platform.service';
import { SEOService } from './shared/services/seo.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, NavbarComponent, HomeComponent, FooterComponent],
})
export class AppComponent {
  currentPath!: string;
  routeSub$!: Subscription;

  seoService = inject(SEOService);
  platform = inject(PlatformService);
  location = inject(Location);

  constructor() {
    this.addTitleAndTags();
  }
  ngOnInit(): void {
    if (this.platform.isBrowser) {
      let script = document.createElement('script');
      script.type = `application/ld+json`;
      script.text = `
      {
        "@context" : "https://schema.org",
        "@type" : "WebSite",
        "name" : "جمعية عزوة",
        "alternateName" : "Ezoah",
        "url" : ${environment.webSite},
        "potentialAction": {
          "@type": "SearchAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": ${environment.webSite}
          },
          "query-input": "required name=جمعية عزوة"
        }
      }
      `;
      document.head.append(script);
    }
  }

  addTitleAndTags() {
    this.seoService.setPageTitle('جمعية عزوة');
    this.seoService.addPageTags([
      {
        name: 'og:image',
        content:
          'https://test-bucket-b5lgh23.s3.eu-west-1.amazonaws.com/1718048541884-5f508d57-df2c-4c13-b180-58724f9560ec.jpg',
      },
      {
        name: 'twitter:image',
        content:
          'https://test-bucket-b5lgh23.s3.eu-west-1.amazonaws.com/1718048541884-5f508d57-df2c-4c13-b180-58724f9560ec.jpg',
      },
      {
        name: 'description',
        content:
          'جمعية أهلية مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم (2232) وتاريخ 1443/07/01 هـ',
      },
      {
        name: 'og:description',
        content:
          'جمعية أهلية مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم (2232) وتاريخ 1443/07/01 هـ',
      },
      {
        name: 'og:title',
        content: 'جمعية عزوة',
      },
      {
        name: 'twitter:description',
        content:
          'جمعية أهلية مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم (2232) وتاريخ 1443/07/01 هـ',
      },
      {
        name: 'twitter:title',
        content: 'جمعية عزوة',
      },
      {
        name: 'title',
        content: 'جمعية عزوة',
      },
      {
        name: 'description',
        content:
          'جمعية أهلية مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم (2232) وتاريخ 1443/07/01 هـ',
      },
      {
        name: 'og:locale',
        content: 'ar_AR',
      },
      {
        name: 'og:locale:alternate',
        content: 'ar_AR',
      },
      {
        name: 'og:url',
        content: this.location.prepareExternalUrl('/'),
      },
      // {
      //   name: 'article:publisher',
      //   content: 'facebook url',
      // },
      {
        name: 'twitter:site',
        content: 'https://twitter.com/EzoahKSA',
      },
      {
        name: 'og:site_name',
        content: 'جمعية عزوة',
      },
      {
        name: 'og:type',
        content: 'website',
      },
    ]);
    this.seoService.addCanonicalURL();
  }
}
