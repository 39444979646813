import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'registration-certificate',
    loadComponent: () =>
      import(
        './pages/registration-certificate/registration-certificate.component'
      ).then((c) => c.RegistrationCertificateComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/privacy-page/privacy-page.component').then(
        (c) => c.PrivacyPageComponent
      ),
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./home/home.component').then((c) => c.HomeComponent),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./pages/error-404/error-404.component').then(
        (c) => c.Error404Component
      ),
  },
  // {
  //   path: '**',
  //   component: HomeComponent,
  // },
  { path: '**', redirectTo: '404' },
];
