import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MembershipFormComponent } from '../membership-form/membership-form.component';
import { Router } from '@angular/router';
import { ScrollService } from '../../services/scroll.service';
import { ContactService } from '../../services/contact.service';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-navbar-action-menu',
  standalone: true,
  imports: [],
  templateUrl: './navbar-action-menu.component.html',
  styleUrl: './navbar-action-menu.component.scss',
})
export class NavbarActionMenuComponent {
  constructor(
    private dialogRef: DialogRef<NavbarActionMenuComponent>,
    private _dialog: MatDialog,
    private _ScrollService: ScrollService,
    private _ContactService: ContactService,
    private router: Router
  ) {}

  closeMenu() {
    this.dialogRef.close();
  }

  goTovolunteerPage() {
    window.open('https://nvg.gov.sa/auth/login', '_blank');
    this.closeMenu();
  }

  openMembershipForm() {
    this._dialog.open(MembershipFormComponent, {
      position: { top: '3rem' },
      scrollStrategy: new NoopScrollStrategy(),
      maxWidth: '90vw',
    });
    this.closeMenu();
  }

  scrollToSection(sectionId: string) {
    if (this.router.url !== '/home') {
      this.router.navigateByUrl('home');
    }
    setTimeout(() => {
      this._ScrollService.sendScrollEvent(sectionId);
    }, 200);
  }

  contactUsForJob(value: boolean) {
    this._ContactService.setContactPurposeSubj(value);
    this.scrollToSection('contactUsSection');
    this.closeMenu();
  }
}
