import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ScrollService } from '../../shared/services/scroll.service';
import { ContactService } from '../../shared/services/contact.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  constructor(
    private _ScrollService: ScrollService,
    private router: Router,
    private _ContactService: ContactService
  ) {}

  scrollToSection(sectionId: string) {
    if (this.router.url !== '/home') {
      this.router.navigateByUrl('home');
    }
    setTimeout(() => {
      this._ScrollService.sendScrollEvent(sectionId);
    }, 200);
  }

  contactUsForJob(value: boolean) {
    this._ContactService.setContactPurposeSubj(value);
    this.scrollToSection('contactUsSection');
  }
}
