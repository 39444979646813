<div class="action-menu-container">
  <div class="close-icon" (click)="closeMenu()">
    <img
      loading="lazy"
      src="../../../../assets/images/x-mark_solid.svg"
      alt="close"
    />
  </div>
  <div class="heading-3" title="انضم إلينا">انضم إلينا</div>
  <div class="actions d-flex">
    <button
      mat-button
      class="action-btn d-flex primary-btn"
      (click)="openMembershipForm()"
    >
      عضو
    </button>
    <button
      mat-button
      class="action-btn d-flex primary-btn"
      (click)="goTovolunteerPage()"
    >
      <div class="d-flex">
        متطوع
        <span class="icon d-flex">
          <img
            loading="lazy"
            src="../../../../assets/images/arrow-up-left_mini.svg"
            alt="متطوع"
          />
        </span>
      </div>
    </button>
    <button
      mat-button
      class="action-btn d-flex primary-btn"
      (click)="contactUsForJob(true)"
    >
      توظيف
    </button>
  </div>
</div>
