@if(!_platForm.isMobile){
<div class="navbar w-100">
  <div class="container d-flex">
    <div class="logo" (click)="scrollToSection('homeSection')">
      <img loading="lazy" src="../../../assets/images/Logo.svg" alt="عزوة" />
    </div>
    <div class="navlinks d-flex">
      <div
        class="navlink"
        [class.active]="activeSection === 'aboutSection'"
        (click)="scrollToSection('aboutSection')"
      >
        عن عزوة
        <!-- <a routerLink="home">عن عزوة</a> -->
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'journeySection'"
        (click)="scrollToSection('journeySection')"
      >
        تجربتك
        <!-- <a routerLink="home">تجربتك</a> -->
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'directorsSection'"
        (click)="scrollToSection('directorsSection')"
      >
        مجلس الإدارة
        <!-- <a routerLink="home">مجلس الإدارة</a> -->
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'initiativesSection'"
        (click)="scrollToSection('initiativesSection')"
      >
        مبادراتنا
        <!-- <a routerLink="home">مبادراتنا</a> -->
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'librarySection'"
        (click)="scrollToSection('librarySection')"
      >
        المكتبة الرقمية
        <!-- <a routerLink="home">المكتبة الرقمية</a> -->
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'contactUsSection'"
        (click)="scrollToSection('contactUsSection')"
      >
        تواصل معنا
        <!-- <a routerLink="home">تواصل معنا</a> -->
      </div>
    </div>
    <div class="nav-actions d-flex">
      <button
        mat-button
        class="action-btn d-flex join-us"
        (click)="openActionMenu()"
      >
        انضم إلينا
      </button>
      <button
        mat-button
        class="action-btn d-flex primary-btn"
        (click)="goToConsumerApp()"
      >
        <div class="d-flex">
          بوابة الخدمة
          <span class="icon d-flex">
            <img
              loading="lazy"
              src="../../../assets/images/arrow-up-left_mini.svg"
              alt="انضم إلينا"
            />
          </span>
        </div>
      </button>
    </div>
  </div>
</div>
}@else {
<div class="navbar responsive">
  <div class="container d-flex">
    <div class="logo" (click)="scrollToSection('homeSection')">
      <img loading="lazy" src="../../../assets/images/Logo.svg" alt="عزوة" />
    </div>

    <div class="nav-actions d-flex">
      @if(!menu){
      <button
        mat-button
        class="action-btn d-flex join-us"
        (click)="openActionMenu()"
      >
        انضم إلينا
      </button>
      }
      <span class="icon d-flex menu" (click)="!menu ? openMenu() : closeMenu()">
        <img
          loading="lazy"
          [src]="
            !menu
              ? '../../../assets/images/menu.svg'
              : '../../../assets/images/close-menu.svg'
          "
          [alt]="menu ? 'menu' : 'close menu'"
        />
      </span>
    </div>
  </div>
  @if(menu){
  <div class="side-menu">
    <div class="navlinks d-flex">
      <div
        class="navlink"
        [class.active]="activeSection === 'aboutSection'"
        (click)="scrollToSection('aboutSection')"
      >
        عن عزوة
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'journeySection'"
        (click)="scrollToSection('journeySection')"
      >
        تجربتك
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'directorsSection'"
        (click)="scrollToSection('directorsSection')"
      >
        مجلس الإدارة
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'initiativesSection'"
        (click)="scrollToSection('initiativesSection')"
      >
        مبادراتنا
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'librarySection'"
        (click)="scrollToSection('librarySection')"
      >
        المكتبة الرقمية
      </div>
      <div
        class="navlink"
        [class.active]="activeSection === 'contactUsSection'"
        (click)="scrollToSection('contactUsSection')"
      >
        تواصل معنا
      </div>
    </div>
    <div class="nav-actions d-flex">
      <button
        mat-button
        class="action-btn d-flex join-us"
        (click)="openActionMenu()"
      >
        انضم إلينا
      </button>
      <button
        mat-button
        class="action-btn d-flex primary-btn"
        (click)="goToConsumerApp()"
      >
        <div class="d-flex">
          بوابة الخدمة
          <span class="icon d-flex">
            <img
              loading="lazy"
              src="../../../assets/images/arrow-up-left_mini.svg"
              alt="انضم إلينا"
            />
          </span>
        </div>
      </button>
    </div>
  </div>

  }
</div>
}
