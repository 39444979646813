import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { ScrollService } from '../../shared/services/scroll.service';
import { MatDialog } from '@angular/material/dialog';
import { NavbarActionMenuComponent } from '../../shared/components/navbar-action-menu/navbar-action-menu.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { PlatformService } from '../../shared/services/platform.service';
import { ContactService } from '../../shared/services/contact.service';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MatButtonModule, RouterLink],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
  activeSection!: string;
  menu: boolean = false;
  constructor(
    private _ScrollService: ScrollService,
    private _ContactService: ContactService,
    private router: Router,
    private _dialog: MatDialog,
    public _platForm: PlatformService
  ) {
    this._ScrollService.sectionInViewSubjectObs.subscribe((elementId) => {
      this.activeSection = elementId;
    });
  }

  ngOnInit() {
    if (this._platForm.isBrowser) {
      this.router.events.subscribe((val) => {
        if (this.router.url !== '/home') {
          this._ScrollService.sectionInViewSubject.next('');
        }
      });
    }
  }

  scrollToSection(sectionId: string) {
    if (this.menu) this.closeMenu();
    if (this.router.url !== '/home') {
      this.router.navigateByUrl('home');
    }
    if(sectionId === 'contactUsSection'){
      this._ContactService.setContactPurposeSubj(false);
    }
    setTimeout(() => {
      this._ScrollService.sendScrollEvent(sectionId);
    }, 500);
  }

  goToConsumerApp() {
    window.open('https://consumer.ezoah.com/', '_blank');
  }

  openActionMenu() {
    this._dialog.open(NavbarActionMenuComponent, {
      position: { top: this._platForm.isMobile ? '15rem' : '5rem' },
      scrollStrategy: new NoopScrollStrategy(),
      maxWidth: '90vw',
      width: '80rem',
    });
    this.closeMenu();
  }

  openMenu() {
    this.menu = true;
    if (this._platForm.isBrowser) {
      document.body.classList.add('no-scroll');
    }
  }

  closeMenu() {
    this.menu = false;
    if (this._platForm.isBrowser) {
      document.body.classList.remove('no-scroll');
    }
  }
}
